import React from 'react';
import styled from 'styled-components';
import { Container } from 'reactstrap';
import breakpoint from 'styled-components-breakpoint';
import { navigate } from 'gatsby';

import Button from 'components/Common/Button';

const Contact = styled(Container)`
  margin-top: 64px;
  margin-bottom: 64px;
  text-align: center;
  h2 {
    ${props => props.theme.main.fonts.displayLarge}
  }

  ${breakpoint('md')`
    h2 {
      ${props => props.theme.main.fonts.displayXLarge}
    }
  `}
`;

export default () => {
  return (
    <Contact>
      <h2>We help brands and startups create the next generation of digital products</h2>
      <Button onClick={() => navigate("/contact/")}>Let's create something great</Button>
    </Contact>)
}
