import React from 'react';
import { Container, Col, Row } from 'reactstrap';
import { Link } from 'gatsby';
import { navigate } from 'gatsby';
import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';

import Button from 'components/Common/Button';

import { FaFacebookF } from 'react-icons/fa';
import { FaTwitter } from 'react-icons/fa';
import { FaLinkedinIn } from 'react-icons/fa';
import { FaInstagram } from 'react-icons/fa';

const FooterWrapper = styled.div`
	background-color: ${props => props.theme.main.colors.grey.light};
	padding-top: 24px;

	${breakpoint('sm')`
		padding-top: 0;
		margin-top: 48px;
		background-color: transparent;
	`}
`

const Contact = styled(Col)`
	text-align: left;

	h3 {
		${props => props.theme.main.fonts.displayLarge}
		margin-bottom: 0;
	}
	h4 {
		${props => props.theme.main.fonts.displayLargeG}
	}
`

const Links = styled(Col)`
	margin-top: 36px;
	margin-bottom: 30px;
	display: flex;
	flex-direction: column;

	${breakpoint('sm')`
		margin-top: 0px;
	`}

	a {
		${props => props.theme.main.fonts.body}
		margin-bottom: 8px;
	}
`;

const ContactInfo = styled(Col)`
	margin-top: 36px;

	p {
		${props => props.theme.main.fonts.body}
		margin-bottom: 0;
	}
	a {
		display: block;
		${props => props.theme.main.fonts.body}
		color: ${props => props.theme.main.colors.blue.regular};
		margin-top: 8px;
		margin-bottom: 8px;
	}

	${breakpoint('sm')`
		margin-top: 0px;
	`}
`;

const Social = styled(Row)`
	border-top: 1px solid ${props => props.theme.main.colors.grey.regular};
	margin-top: 24px;
	padding-top: 16px;
	padding-bottom: 16px;
	font-size: 22px;
	&>div {
		text-align: center;
	}
	svg {
		fill: ${props => props.theme.main.colors.grey.darker};
	}
`;

const Footer = () => (
	<FooterWrapper>
		<Container>
			<Row>
				<Contact sm="6">
					<h3>Got a Project?</h3>
					<h4>Let's Talk</h4>
					<Button onClick={() => navigate("/contact/")}>Start a Project</Button>
				</Contact>

				<Links xs="4" sm="3">
					<Link to="/services/">Services</Link>
					<Link to="/projects/">Projects</Link>
					<Link to="/about/">About Us</Link>
					<Link to="/blog/">Blog</Link>
				</Links>

				<ContactInfo xs="8" sm="3">
					<Link to="/contact/">Email us</Link>
					<p>1-604-398-4008</p>
				</ContactInfo>
			</Row>
			<Social>
				<Col xs="0" md="8"/>
				<Col xs="3" md="1">
					<a href="https://www.facebook.com/Calicologic/"	target="_blank" rel="noopener noreferrer">
						<FaFacebookF />
					</a>
				</Col>
				<Col xs="3" md="1">
					<a href="https://twitter.com/calicologic" target="_blank" rel="noopener noreferrer">
						<FaTwitter />
					</a>
				</Col>
				<Col xs="3" md="1">
					<a href="https://www.linkedin.com/company/calico-logic" target="_blank" rel="noopener noreferrer">
						<FaLinkedinIn/>
					</a>
				</Col>
				<Col xs="3" md="1">
					<a href="https://www.instagram.com/calicologic/" target="_blank" rel="noopener noreferrer">
						<FaInstagram />
					</a>
				</Col>
			</Social>
		</Container>
	</FooterWrapper>
);

export default Footer;
