import React, {useEffect, useState} from 'react';
import {Container, Col, Row,} from 'reactstrap'; //8.8.1
import styled from 'styled-components';
import {Link} from 'gatsby';
import {breakpoint} from 'styled-components-breakpoint';

import {FaCaretRight} from 'react-icons/fa';

import Technology from './Technology';

const Values = styled.div`
  overflow: hidden;

  h3 {
    ${props => props.theme.main.fonts.displayLarge}
  }

  p {
    ${props => props.theme.main.fonts.body}
  }

  a {
    ${props => props.theme.main.fonts.textLink}
    text-decoration: none;
  }

`;

const Value = styled.div`
  position: relative;
  margin-bottom: 20px;

  & > div {
    background-color: ${props => props.theme.main.colors.grey.light};
    position: relative;
    width: 250px;
    height: 250px;
    border-radius: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    cursor: pointer;
    transition: all 500ms;

    z-index: 1;

    h5 {
      ${props => props.theme.main.fonts.heading}
      text-align: center;
      margin-bottom: 3px;
    }

    p {
      ${props => props.theme.main.fonts.body}
      margin-bottom: 0;
    }

    img {
      width: 75px;
    }

    ${breakpoint('xs')`
      margin-left: auto;
      margin-right: auto;
    `}
    
  }
  
  
  &:focus {
    outline: none;
  }

  &:hover {
    & > div {
      transform: scale(1.1, 1.1);
      background-color: ${props => props.theme.main.colors.orange.regular};

      h5, p {
        color: white;
      }

      img {
        filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(305deg) brightness(103%) contrast(103%);
      }
    }

    &:before {
      opacity: 1;
      bottom: -12px;
    }
  }

  &:before {
    opacity: 0;
    left: 50%;
    transform: translateX(-50%);
    content: '';
    position: relative;
    background-color: ${props => props.theme.main.colors.orange.regular};
    box-shadow: 0 2px 50px 0 rgba(33, 43, 54, 0.48), 0 2px 50px 0 rgba(240, 109, 28, 0.65);
    bottom: 0;
    z-index: -1;
    transition: bottom 500ms, opacity 500ms;
  }
`;

export default ({values}) => {

    const [numberToShow, setNumberToShow] = useState(4)

    //choose the screen size
    const handleResize = () => {
        if (window.innerWidth < 1200 && window.innerWidth >= 992) {
            setNumberToShow(3)
        }
        else if (window.innerWidth < 992 && window.innerWidth >= 768) {
            setNumberToShow(2)
        }
        else if (window.innerWidth < 768) {
            setNumberToShow(1)
        }
        else {
            setNumberToShow(4)
        }
    }
    // create an event listener
    useEffect(() => {
        window.addEventListener("resize", handleResize)
    })

    return (
        <Values>
            <Container>
                <h3>Calico's Core Values</h3>
                <p>Why Calico is unique</p>
                <p></p>
                <Row>
                    {values.slice(0, numberToShow).map((value, i) =>
                        <Col key={i}>
                            <Value key={i}>
                                <div key={i}>
                                    <img src={value.icon.file.url}/>
                                    <h5>{value.name}</h5>
                                    <p>{value.subtitle}</p>
                                    <p>{value.description.description}</p>
                                </div>
                            </Value>
                        </Col>
                    )}
                </Row>
                <Technology/>
                <Link to="/services/">All services <FaCaretRight/></Link>
            </Container>
        </Values>)

}