import React from 'react';
import { graphql } from 'gatsby'

import Layout from 'components/Layout/Layout';
import SEO from 'components/seo';
import Home from 'components/Home/Home';


const IndexPage = ({data}) => (
	<Layout>
		<SEO title="Software Development" description="We are technology and community-driven, focusing on developing custom software solutions for a wide array of businesses." />
		<Home rawQueryData={data}/>
	</Layout>
)


export const query = graphql`
{
 allContentfulService {
    nodes {
      isFeatured
      title
      technologyBulletList
      anchor
      icon {
        file {
          url
        }
      }
    }
  }
  allContentfulValues {
    nodes {
      name
      id
      subtitle
      icon {
        file {
          url
        }
      }
      description {
        description
      }
    }
  }
  allContentfulProject(filter: {isActive: {eq: true}}, sort: {fields: [sortOrder], order: ASC}) {
    nodes {
      slug
      heading
      subHeading
      projectPageThumbnail {
        fluid(maxWidth: 535) {
          ...GatsbyContentfulFluid
        }
      }
    }
  }
  contentfulCommunity {
    header
    body {
      json
    }
    image {
      fluid(maxWidth: 537) {
        ...GatsbyContentfulFluid
      }
    }
  }
  allContentfulBlogPost {
    nodes {
      title
      slug
    }
  }
}
`
export default IndexPage;
