import React from 'react';
import styled from 'styled-components';
import { Container } from 'reactstrap';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import Img from 'gatsby-image';
import breakpoint from 'styled-components-breakpoint';

import circleImage from 'images/green.circle.svg';

const Community = styled.div`
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  -ms-grid-rows: 150px auto 1fr 1fr;
  grid-template-rows: 150px auto 1fr 1fr;
`;

const StyledContainer = styled(Container)`
  -ms-grid-row: 1;
  -ms-grid-row-span: 2;
  grid-row: 1 / 3;
  -ms-grid-column: 1;
  -ms-grid-column-span: 6;
  grid-column: 1 / 7;
  z-index: 2;

  h3 {
    ${props => props.theme.main.fonts.displayLarge}
    margin-bottom: 16px;
  }
  p {
    ${props => props.theme.main.fonts.body}
  }

  ${breakpoint('md')`
    -ms-grid-row: 2;
    -ms-grid-row-span: 3;
    grid-row: 2 / 5;
    p {
      width: 60%;
    }
  `}
`;

const Image = styled.div`
  -ms-grid-row: 3;
  -ms-grid-row-span: 2;
  grid-row: 3 / 5;
  -ms-grid-column: 3;
  -ms-grid-column-span: 4;
  grid-column: 3 / 7;
  -ms-grid-row-align: center;
  align-self: center;

  ${breakpoint('md')`
    -ms-grid-row: 2;
    -ms-grid-row-span: 3;
    grid-row: 2 / 5;
    -ms-grid-column: 5;
    -ms-grid-column-span: 2;
    grid-column: 5 / 7;
    -ms-grid-row-align: start;
    align-self: start;
  `}

  position: relative;
`;

const Background = styled.div`
  -ms-grid-row: 3;
  -ms-grid-row-span: 2;
  grid-row: 3 / 5;
  -ms-grid-column: 4;
  -ms-grid-column-span: 3;
  grid-column: 4 / 7;
  -ms-grid-column-align: right;
  justify-self: right;
  text-align: right;

  ${breakpoint('md')`
    -ms-grid-row: 1;
    -ms-grid-row-span: 4;
    grid-row: 1 / 5;
    -ms-grid-column: 5;
    -ms-grid-column-span: 2;
    grid-column: 5 / 7;
  `}

  svg {
    width: 100%;
    height: 100%;
  }
`;


export default ({community}) => {
  return (
    <Community>
      <StyledContainer>
        <h3>{community.header}</h3>
        {documentToReactComponents(community.body.json)}
      </StyledContainer>
      <Image>
        <Img alt={community.header} fluid={community.image.fluid} />
      </Image>
      <Background>
        <img src={circleImage} />
      </Background>
    </Community>);
}