import React, { useState }  from 'react';
import { Link } from 'gatsby';
import { Container, Collapse, Navbar, NavbarToggler, NavbarBrand, Nav, NavItem } from 'reactstrap';
import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';

import Logo from 'images/logo.inline.svg';

const StyledNavbar = styled(Navbar)`
  z-index: 3;

  .navbar-nav {
    margin-left: auto;
    .nav-item {
      margin: 0 20px;
      align-self: center;
      a {
        ${props => props.theme.main.fonts.heading}
        &:hover {
          text-decoration: none;
        }
      }
      &:last-of-type {
        margin-right: 0;
      }
    }
  }
  .navbar-collapse {
    &.show, &.collapsing {
      background-color: white;
      position: absolute;
      width: 100vw;
      height: 100vh;
      z-index: 3;
      left: 0;
      top: 95px;
      .nav-item {
        align-self: flex-start;
        margin-bottom: 16px;
        margin-left: 20px;
        &:first-of-type {
          margin-top: 20px;
        }
      }
    }
  }
`;

const NavItemButton = styled(NavItem)`
  &&& {
    background-color: ${props => props.theme.main.colors.orange.regular};
    padding: 6px 32px;
    border-radius: 18px;
    transition: all 500ms;
    a {
      color: white;
    }
    &:hover {
      background-color: ${props => props.theme.main.colors.orange.dark};
    }
  }
`;

const StyledLogo = styled(Logo)`
  width: 100px;
`;

export default () => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

  return (
      <StyledNavbar light expand="md">
        <Container>
          <NavbarBrand href="/"><StyledLogo /></NavbarBrand>
          <NavbarToggler onClick={toggle} />
          <Collapse isOpen={isOpen} navbar>
            <Nav navbar>
              <NavItem>
                <Link to="/">Home</Link>
              </NavItem>
              <NavItem>
                <Link to="/projects/">Projects</Link>
              </NavItem>
              <NavItem>
                <Link to="/services/">Services</Link>
              </NavItem>
              <NavItem>
                <Link to="/about/">About</Link>
              </NavItem>
              <NavItem>
                <Link to="/blog/">Blog</Link>
              </NavItem>
              <NavItemButton>
                <Link to="/contact/">Contact</Link>
              </NavItemButton>
            </Nav>
          </Collapse>
        </Container>
      </StyledNavbar>);
}