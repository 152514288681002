import React from 'react';
import styled from 'styled-components';
import { Container, Row, Col } from 'reactstrap';

import BlogPost from './BlogPost';

const Updates = styled(Container)`
  h3 {
    ${props => props.theme.main.fonts.displayLarge}
    margin-bottom: 0;
  }
  h4 {
    ${props => props.theme.main.fonts.displayLargeG}
  }
`;
export default ({posts = []}) => {
  return (
    <Updates>
      <Row>
        <Col md="5">
          <h3>We also write</h3>
          <h4>Our blog and updates</h4>
        </Col>
        <Col md="7">
          {posts.sort((a, b) => new Date(b.date) - new Date(a.date)).slice(0, 3).map((post, i) => <BlogPost post={post} key={i} latest={(i === 0)} />)}
        </Col>
      </Row>
    </Updates>);
}