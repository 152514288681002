import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import { Container } from 'reactstrap';
import breakpoint from 'styled-components-breakpoint';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
//import Slider from "react-slick";
//import "slick-carousel/slick/slick.css";
//import "slick-carousel/slick/slick-theme.css";

import Project from './Project';

const Work = styled(Container)`
  margin-top: 64px;
  h3 {
    ${props => props.theme.main.fonts.displayLarge}
    margin-bottom: 0;
  }
  h4 {
    ${props => props.theme.main.fonts.displayLargeG}
  }
`;

const Projects = styled.div`
  display: flex;
  justify-content: space-between;
  margin-left: -15px;
  margin-right: -15px;
  ${breakpoint('md')`
    margin-left: 0;
    margin-right: 0;
  `}
  &>a {
    &:first-of-type {
      margin-right: 10px;
      ${breakpoint('md')`
        margin-right: 40px;
      `}
    }
  }
`;

const Indicator = styled.span`
          
    `;

export default ({projects}) => {
    const [elementVis, setElementVis] = useState(true)

    const handleResize = () => {
        if (window.innerWidth < 450) {
            setElementVis(false)
        }
        else {
            setElementVis(true)
        }
    }
// create an event listener
    useEffect(() => {
        window.addEventListener("resize", handleResize)
    })
    const arrowStyles = {
        position: 'absolute',
        zIndex: 2,
        top: 'calc(35%)',
        width: 32,
        height: 32,
        cursor: 'pointer',
        borderRadius: 12,
        opacity: 0.7,
        border: '1px solid grey',
    };

    const indicatorStyles = {
        background: 'grey',
        width: 8,
        height: 8,
        display: 'inline-block',
        borderRadius: 5,
        margin: '0 8px',
        opacity: 0.5,
    };

    const sliderSettings = {
        arrows: true,
        centerMode: true,
        dots: true,
        speed: 500,
        slide: 'Project',
        slidesToShow: 5,
        slidesToScroll: 1,
    };

    const sliderStyle = {
        width: '100%',
    }

    const carouselStyle = {

}

  return (
    <Work name="projects">
      <h3>Our Work</h3>
      <h4>Selected Projects</h4>
      <Projects>
          <div style={{width: '100%'}}>
          <Carousel
              style={{...carouselStyle}}
              showThumbs={false}
              autoPlay={false}
              centerMode={true}
              centerSlidePercentage={40}
              infiniteLoop={false}
              showArrows={elementVis}
              showIndicators={true}
              showStatus={false}
              swipeable={true}
              emulateTouch={true}
              renderArrowPrev={(onClickHandler, hasPrev, label) =>
                  hasPrev && (
                      <button type="button" onClick={onClickHandler} title={label} style={{ ...arrowStyles, left: 15 }} >
                          &larr;
                      </button>
                  )
              }
              renderArrowNext={(onClickHandler, hasNext, label) =>
                  hasNext && (
                      <button type="button" onClick={onClickHandler} title={label} style={{ ...arrowStyles, right: 15 }}>
                          &rarr;
                      </button>
                  )
              }
              renderIndicator={(onClickHandler, isSelected, index, label) => {
                  const defStyle = indicatorStyles;
                  const style = isSelected
                      ? { ...defStyle, backgroundColor: "orange" }
                      : { ...defStyle, backgroundColor: "grey" };
                  return (
                      <Indicator
                          style={style}
                          onClick={onClickHandler}
                          onKeyDown={onClickHandler}
                          key={index}
                          role="button"
                          tabIndex={0}
                          aria-label={`${label} ${index + 1}`}
                      >
                      </Indicator>
                  );
              }}
          >
              {projects.map((project, i) => <Project project={project} key={i}/>)}
          </Carousel>
          {/*<Slider {...sliderSettings} style={{sliderStyle}}>
              {projects.map((project, i) => <Project project={project} key={i}/>)}
          </Slider>*/}
          </div>
      </Projects>
    </Work>
  );
}