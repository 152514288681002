import React from 'react';
import styled from 'styled-components';

const StyledButton = styled.button`
  ${props => props.theme.main.fonts.heading}
  background-color: ${props => props.theme.main.colors.blue.regular};
  padding: 6px 32px;
  border-radius: 18px;
  color: white;
  transition: all 500ms;
  border: none;
  &:focus {
    outline: none;
  }
  &:hover {
    background-color: ${props => props.theme.main.colors.blue.dark};
  }
`;

export default ({children, onClick=null}) => {
  return (
    <StyledButton onClick={onClick}>
      {children}
    </StyledButton>)
}