import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

import { FaCaretRight } from 'react-icons/fa';

const BlogPost = styled.div`
  display: flex;
  flex-wrap: wrap;
  border-bottom: 1px solid ${props => props.theme.main.colors.grey.regular};
  margin-bottom: 20px;
  .date {
    ${props => props.theme.main.fonts.headingLight}
    flex: 100%
  }
  p {
    ${props => props.theme.main.fonts.bodyLight}
    flex: 1 0 30%;
    margin-bottom: 16px;
  }
  a {
    ${props => props.theme.main.fonts.textLink}
    text-decoration: underline;
  }
`;


export default ({post, latest = false}) => {
  return(
    <BlogPost>
      <div className="date">{latest ? 'Latest' : post.date}</div>
      <p>{post.title}</p>
      <Link to={`/blog/${post.slug}`}>Read More <FaCaretRight /></Link>
    </BlogPost>);
}